(function () {
    var NumberT, StringT, utils;
    NumberT = require('./Number').Number;
    utils = require('./utils');
    StringT = function () {
        function StringT(length, encoding) {
            this.length = length;
            this.encoding = encoding != null ? encoding : 'ascii';
        }
        StringT.prototype.decode = function (stream, parent) {
            var buffer, encoding, length, pos, string;
            length = function () {
                if (this.length != null) {
                    return utils.resolveLength(this.length, stream, parent);
                } else {
                    buffer = stream.buffer, length = stream.length, pos = stream.pos;
                    while (pos < length && buffer[pos] !== 0) {
                        ++pos;
                    }
                    return pos - stream.pos;
                }
            }.call(this);
            encoding = this.encoding;
            if (typeof encoding === 'function') {
                encoding = encoding.call(parent, parent) || 'ascii';
            }
            string = stream.readString(length, encoding);
            if (this.length == null && stream.pos < stream.length) {
                stream.pos++;
            }
            return string;
        };
        StringT.prototype.size = function (val, parent) {
            var encoding, size;
            if (!val) {
                return utils.resolveLength(this.length, null, parent);
            }
            encoding = this.encoding;
            if (typeof encoding === 'function') {
                encoding = encoding.call(parent != null ? parent.val : void 0, parent != null ? parent.val : void 0) || 'ascii';
            }
            if (encoding === 'utf16be') {
                encoding = 'utf16le';
            }
            size = Buffer.byteLength(val, encoding);
            if (this.length instanceof NumberT) {
                size += this.length.size();
            }
            if (this.length == null) {
                size++;
            }
            return size;
        };
        StringT.prototype.encode = function (stream, val, parent) {
            var encoding;
            encoding = this.encoding;
            if (typeof encoding === 'function') {
                encoding = encoding.call(parent != null ? parent.val : void 0, parent != null ? parent.val : void 0) || 'ascii';
            }
            if (this.length instanceof NumberT) {
                this.length.encode(stream, Buffer.byteLength(val, encoding));
            }
            stream.writeString(val, encoding);
            if (this.length == null) {
                return stream.writeUInt8(0);
            }
        };
        return StringT;
    }();
    module.exports = StringT;
}.call(this));