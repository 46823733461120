(function () {
    var ArrayT, LazyArray, LazyArrayT, NumberT, inspect, utils, __hasProp = {}.hasOwnProperty, __extends = function (child, parent) {
            for (var key in parent) {
                if (__hasProp.call(parent, key))
                    child[key] = parent[key];
            }
            function ctor() {
                this.constructor = child;
            }
            ctor.prototype = parent.prototype;
            child.prototype = new ctor();
            child.__super__ = parent.prototype;
            return child;
        };
    ArrayT = require('./Array');
    NumberT = require('./Number').Number;
    utils = require('./utils');
    inspect = require('util').inspect;
    LazyArrayT = function (_super) {
        __extends(LazyArrayT, _super);
        function LazyArrayT() {
            return LazyArrayT.__super__.constructor.apply(this, arguments);
        }
        LazyArrayT.prototype.decode = function (stream, parent) {
            var length, pos, res;
            pos = stream.pos;
            length = utils.resolveLength(this.length, stream, parent);
            if (this.length instanceof NumberT) {
                parent = {
                    parent: parent,
                    _startOffset: pos,
                    _currentOffset: 0,
                    _length: length
                };
            }
            res = new LazyArray(this.type, length, stream, parent);
            stream.pos += length * this.type.size(null, parent);
            return res;
        };
        LazyArrayT.prototype.size = function (val, ctx) {
            if (val instanceof LazyArray) {
                val = val.toArray();
            }
            return LazyArrayT.__super__.size.call(this, val, ctx);
        };
        LazyArrayT.prototype.encode = function (stream, val, ctx) {
            if (val instanceof LazyArray) {
                val = val.toArray();
            }
            return LazyArrayT.__super__.encode.call(this, stream, val, ctx);
        };
        return LazyArrayT;
    }(ArrayT);
    LazyArray = function () {
        function LazyArray(type, length, stream, ctx) {
            this.type = type;
            this.length = length;
            this.stream = stream;
            this.ctx = ctx;
            this.base = this.stream.pos;
            this.items = [];
        }
        LazyArray.prototype.get = function (index) {
            var pos;
            if (index < 0 || index >= this.length) {
                return void 0;
            }
            if (this.items[index] == null) {
                pos = this.stream.pos;
                this.stream.pos = this.base + this.type.size(null, this.ctx) * index;
                this.items[index] = this.type.decode(this.stream, this.ctx);
                this.stream.pos = pos;
            }
            return this.items[index];
        };
        LazyArray.prototype.toArray = function () {
            var i, _i, _ref, _results;
            _results = [];
            for (i = _i = 0, _ref = this.length; _i < _ref; i = _i += 1) {
                _results.push(this.get(i));
            }
            return _results;
        };
        LazyArray.prototype.inspect = function () {
            return inspect(this.toArray());
        };
        return LazyArray;
    }();
    module.exports = LazyArrayT;
}.call(this));