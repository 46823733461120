(function () {
    var DecodeStream, EncodeStream, iconv, stream, __hasProp = {}.hasOwnProperty, __extends = function (child, parent) {
            for (var key in parent) {
                if (__hasProp.call(parent, key))
                    child[key] = parent[key];
            }
            function ctor() {
                this.constructor = child;
            }
            ctor.prototype = parent.prototype;
            child.prototype = new ctor();
            child.__super__ = parent.prototype;
            return child;
        };
    stream = require('stream');
    DecodeStream = require('./DecodeStream');
    try {
        iconv = function () {
            throw new Error('Cannot find module \'iconv-lite\' from \'/Users/devongovett/projects/PDFKit/node_modules/restructure/src\'');
        }();
    } catch (_error) {
    }
    EncodeStream = function (_super) {
        var key;
        __extends(EncodeStream, _super);
        function EncodeStream() {
            EncodeStream.__super__.constructor.apply(this, arguments);
            this.pos = 0;
        }
        for (key in Buffer.prototype) {
            if (key.slice(0, 5) === 'write') {
                (function (key) {
                    var bytes;
                    bytes = DecodeStream.TYPES[key.replace(/write|[BL]E/g, '')];
                    return EncodeStream.prototype[key] = function (value) {
                        var buffer;
                        buffer = new Buffer(+bytes);
                        buffer[key](value, 0);
                        return this.writeBuffer(buffer);
                    };
                }(key));
            }
        }
        EncodeStream.prototype._read = function () {
        };
        EncodeStream.prototype.writeBuffer = function (buffer) {
            this.push(buffer);
            return this.pos += buffer.length;
        };
        EncodeStream.prototype.writeString = function (string, encoding) {
            var buf, byte, i, _i, _ref;
            if (encoding == null) {
                encoding = 'ascii';
            }
            switch (encoding) {
            case 'utf16le':
            case 'ucs2':
            case 'utf8':
            case 'ascii':
                return this.writeBuffer(new Buffer(string, encoding));
            case 'utf16be':
                buf = new Buffer(string, 'utf16le');
                for (i = _i = 0, _ref = buf.length - 1; _i < _ref; i = _i += 2) {
                    byte = buf[i];
                    buf[i] = buf[i + 1];
                    buf[i + 1] = byte;
                }
                return this.writeBuffer(buf);
            default:
                if (iconv) {
                    return this.writeBuffer(iconv.encode(string, encoding));
                } else {
                    throw new Error('Install iconv-lite to enable additional string encodings.');
                }
            }
        };
        EncodeStream.prototype.writeUInt24BE = function (val) {
            var buf;
            buf = new Buffer(3);
            buf[0] = val >>> 16 & 255;
            buf[1] = val >>> 8 & 255;
            buf[2] = val & 255;
            return this.writeBuffer(buf);
        };
        EncodeStream.prototype.writeUInt24LE = function (val) {
            var buf;
            buf = new Buffer(3);
            buf[0] = val & 255;
            buf[1] = val >>> 8 & 255;
            buf[2] = val >>> 16 & 255;
            return this.writeBuffer(buf);
        };
        EncodeStream.prototype.writeInt24BE = function (val) {
            if (val >= 0) {
                return this.writeUInt24BE(val);
            } else {
                return this.writeUInt24BE(val + 16777215 + 1);
            }
        };
        EncodeStream.prototype.writeInt24LE = function (val) {
            if (val >= 0) {
                return this.writeUInt24LE(val);
            } else {
                return this.writeUInt24LE(val + 16777215 + 1);
            }
        };
        EncodeStream.prototype.fill = function (val, length) {
            var buf;
            buf = new Buffer(length);
            buf.fill(val);
            return this.writeBuffer(buf);
        };
        EncodeStream.prototype.end = function () {
            return this.push(null);
        };
        return EncodeStream;
    }(stream.Readable);
    module.exports = EncodeStream;
}.call(this));