(function () {
    var Optional;
    Optional = function () {
        function Optional(type, condition) {
            this.type = type;
            this.condition = condition != null ? condition : true;
        }
        Optional.prototype.decode = function (stream, parent) {
            var condition;
            condition = this.condition;
            if (typeof condition === 'function') {
                condition = condition.call(parent, parent);
            }
            if (condition) {
                return this.type.decode(stream, parent);
            }
        };
        Optional.prototype.size = function (val, parent) {
            var condition;
            condition = this.condition;
            if (typeof condition === 'function') {
                condition = condition.call(parent, parent);
            }
            if (condition) {
                return this.type.size(val, parent);
            } else {
                return 0;
            }
        };
        Optional.prototype.encode = function (stream, val, parent) {
            var condition;
            condition = this.condition;
            if (typeof condition === 'function') {
                condition = condition.call(parent, parent);
            }
            if (condition) {
                return this.type.encode(stream, val, parent);
            }
        };
        return Optional;
    }();
    module.exports = Optional;
}.call(this));