(function () {
    var DecodeStream, iconv;
    try {
        iconv = function () {
            throw new Error('Cannot find module \'iconv-lite\' from \'/Users/devongovett/projects/PDFKit/node_modules/restructure/src\'');
        }();
    } catch (_error) {
    }
    DecodeStream = function () {
        var key;
        function DecodeStream(buffer) {
            this.buffer = buffer;
            this.pos = 0;
            this.length = this.buffer.length;
        }
        DecodeStream.TYPES = {
            UInt8: 1,
            UInt16: 2,
            UInt24: 3,
            UInt32: 4,
            Int8: 1,
            Int16: 2,
            Int24: 3,
            Int32: 4,
            Float: 4,
            Double: 8
        };
        for (key in Buffer.prototype) {
            if (key.slice(0, 4) === 'read') {
                (function (key) {
                    var bytes;
                    bytes = DecodeStream.TYPES[key.replace(/read|[BL]E/g, '')];
                    return DecodeStream.prototype[key] = function () {
                        var ret;
                        ret = this.buffer[key](this.pos);
                        this.pos += bytes;
                        return ret;
                    };
                }(key));
            }
        }
        DecodeStream.prototype.readString = function (length, encoding) {
            var buf, byte, i, _i, _ref;
            if (encoding == null) {
                encoding = 'ascii';
            }
            switch (encoding) {
            case 'utf16le':
            case 'ucs2':
            case 'utf8':
            case 'ascii':
                return this.buffer.toString(encoding, this.pos, this.pos += length);
            case 'utf16be':
                buf = new Buffer(this.readBuffer(length));
                for (i = _i = 0, _ref = buf.length - 1; _i < _ref; i = _i += 2) {
                    byte = buf[i];
                    buf[i] = buf[i + 1];
                    buf[i + 1] = byte;
                }
                return buf.toString('utf16le');
            default:
                buf = this.readBuffer(length);
                if (iconv) {
                    return iconv.decode(buf, encoding);
                }
                return buf;
            }
        };
        DecodeStream.prototype.readBuffer = function (length) {
            return this.buffer.slice(this.pos, this.pos += length);
        };
        DecodeStream.prototype.readUInt24BE = function () {
            return (this.readUInt16BE() << 8) + this.readUInt8();
        };
        DecodeStream.prototype.readUInt24LE = function () {
            return this.readUInt16LE() + (this.readUInt8() << 16);
        };
        DecodeStream.prototype.readInt24BE = function () {
            return (this.readInt16BE() << 8) + this.readUInt8();
        };
        DecodeStream.prototype.readInt24LE = function () {
            return this.readUInt16LE() + (this.readInt8() << 16);
        };
        return DecodeStream;
    }();
    module.exports = DecodeStream;
}.call(this));