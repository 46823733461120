(function () {
    var Bitfield;
    Bitfield = function () {
        function Bitfield(type, flags) {
            this.type = type;
            this.flags = flags != null ? flags : [];
        }
        Bitfield.prototype.decode = function (stream) {
            var flag, i, res, val, _i, _len, _ref;
            val = this.type.decode(stream);
            res = {};
            _ref = this.flags;
            for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
                flag = _ref[i];
                if (flag != null) {
                    res[flag] = !!(val & 1 << i);
                }
            }
            return res;
        };
        Bitfield.prototype.size = function () {
            return this.type.size();
        };
        Bitfield.prototype.encode = function (stream, keys) {
            var flag, i, val, _i, _len, _ref;
            val = 0;
            _ref = this.flags;
            for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
                flag = _ref[i];
                if (flag != null) {
                    if (keys[flag]) {
                        val |= 1 << i;
                    }
                }
            }
            return this.type.encode(stream, val);
        };
        return Bitfield;
    }();
    module.exports = Bitfield;
}.call(this));