(function () {
    var NumberT, PropertyDescriptor;
    NumberT = require('./Number').Number;
    exports.resolveLength = function (length, stream, parent) {
        var res;
        if (typeof length === 'number') {
            res = length;
        } else if (typeof length === 'function') {
            res = length.call(parent, parent);
        } else if (parent && typeof length === 'string') {
            res = parent[length];
        } else if (stream && length instanceof NumberT) {
            res = length.decode(stream);
        }
        if (isNaN(res)) {
            throw new Error('Not a fixed size');
        }
        return res;
    };
    PropertyDescriptor = function () {
        function PropertyDescriptor(opts) {
            var key, val;
            if (opts == null) {
                opts = {};
            }
            this.enumerable = true;
            this.configurable = true;
            for (key in opts) {
                val = opts[key];
                this[key] = val;
            }
        }
        return PropertyDescriptor;
    }();
    exports.PropertyDescriptor = PropertyDescriptor;
}.call(this));