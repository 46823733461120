(function () {
    var BooleanT;
    BooleanT = function () {
        function BooleanT(type) {
            this.type = type;
        }
        BooleanT.prototype.decode = function (stream, parent) {
            return !!this.type.decode(stream, parent);
        };
        BooleanT.prototype.size = function (val, parent) {
            return this.type.size(val, parent);
        };
        BooleanT.prototype.encode = function (stream, val, parent) {
            return this.type.encode(stream, +val, parent);
        };
        return BooleanT;
    }();
    module.exports = BooleanT;
}.call(this));